import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Guid } from 'src/app/helpers/Guid';
import { switchMap } from 'rxjs/operators';
import { IIdentity } from 'src/app/models/IIdentity';
import { Router } from '@angular/router';
import { AppState, TotoAppModule } from '../app.state';
import { ActionHelper } from '../helpers/action';
import { LoginDialogComponent } from '../components/dialogs/login/login.dialog';
import { AccountService } from './account.service';
import { VerificationReminderDialogComponent } from '../components/dialogs/verification-reminder/verification-reminder.dialog';
import { VerificationSuccessDialogComponent } from '../components/dialogs/register-success/verification-success.dialog';
import { MAIN_MENU_LINKS } from '../config/nav-links';
import { LOCAL_STORAGE_KEY } from '../config/local-storage-key';
import { LocalStorage } from '../helpers';
import { BankLoginDialogComponent } from '../components/dialogs/login/bank-login.dialog';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  public isLoggedInChanged: Subject<IIdentity> = new Subject<IIdentity>();
  public showLoginModal = false;

  constructor(
    private _http: HttpClient, 
    private _appState: AppState,
    private _action: ActionHelper,
    private _accountService: AccountService,
    private _router: Router) {

    var storedToken = localStorage.getItem('toto.identity');
    if (storedToken) {
      try {
        this._appState.token.next(JSON.parse(storedToken));
      }
      catch {
        localStorage.removeItem('toto.identity');
      }
    }

    this._appState.token.subscribe(token => {
      localStorage.setItem('toto.identity', JSON.stringify(token));
      this.isLoggedInChanged.next(token);
    });

  };

  public toggleLoginModal(toSet = !this.showLoginModal) {
    this.showLoginModal = toSet;
  }

  public get isLoggedIn() {
    return this._appState.isLoggedIn;
  }

  public getTokenExpiry(): Date {
    if (!this._appState.token?.value) {
      return new Date();
    }
    return new Date(this._appState.token.value.exp * 1000);
  }

  public openLoginDialog(navigateToRoot = false) {
    this._action.openDialog(LoginDialogComponent, {
      height: 'auto',
      width: 'auto',
      data: {}
    }, () => {
      if (navigateToRoot) {
        this._router.navigate([this.activeAppModuleRoot])
      }
    })
  }

  public openBankLoginDialog(navigateToRoot = false) {
    this._action.openDialog(BankLoginDialogComponent, {
      height: 'auto',
      width: '500px',
      data: {}
    }, () => {
      if (navigateToRoot) {
        this._router.navigate([this.activeAppModuleRoot])
      }
    })
  }

  public openVerificationReminderDialog() {
    this._action.openDialog(
      VerificationReminderDialogComponent,
      {
        width: '500px',
        height: 'auto'
      },
      res => {}
    );
  }

  public openVerificationSuccessDialog() {
    this._action.openDialog(
      VerificationSuccessDialogComponent,
      {
        width: '675px',
        height: 'auto'
      },
      res => {}
    );
  }

  public authPassword(username: string, password: string) {

    const svc = this._appState;;
    const sessionId = Guid.newGuid().toString();

    return this._http.post<IIdentity>(`api/auth/password/${sessionId}`, { username, password }).pipe(
      switchMap(async (res: IIdentity) => {
        await this._setAuthCookie(sessionId);

        this._accountService.getAccountInfo().result(null, info => {
          this._appState.setAccountInfo(info, 'log_in');
          svc.token.next(res);
          this.showLoginModal = false;
        })

        return res;
      })
    );
  }

  public get activeAppModuleRoot() {
    return MAIN_MENU_LINKS[this._appState.activeAppModule]?.routerLink
  }

  public async logout() {
    if(window.posthogIdentified) {
      window.posthog.reset();
      window.posthogIdentified = false;
    }

    this.eraseCookie('affiliateClickId');
    this.eraseCookie('affiliateExternalId');

    this._router.navigateByUrl(this.activeAppModuleRoot).then(async _ => {
      await this._clearAuthCookie();
      this._appState.token.next(null);
      this._appState.clearAccountInfo();
    });
  }

  private eraseCookie(name) {   
    document.cookie = name+'=; Path=/;Max-Age=-99999999;';  
  }

  public initAuthenticateViaMobileId(id: string, phone: string, countryCode: string) {    
    return this._http.post<any>('api/auth/mobileId', {
      idCode: id,
      phone: phone,
      prefix: countryCode
    });
  }

  public initAuthenticateViaZimpler(country: string, iframe: boolean) {
    return this._http.post<any>('api/zimpler/identify/', { country: country, iframe: iframe });
  }

  public authenticateViaZimpler(key: any) {
    return this._http.post<any>('api/zimpler/check-identify/' + key, {});
  }

  public initVerifyViaZimpler(country: string) {
    return this._http.post<any>('api/zimpler/verify/', { country: country });
  }

  public initRegisterViaZimpler(req: any) {
    return this._http.post<any>('api/zimpler/register-account/', req);
  }

  public authenticateMobileId(sessionId: string) {
    return this._http.get<any>(`api/auth/mobileId/${sessionId}`);
  }

  public initAuthenticateViaSmartId(idCode: string) {
    return this._http.post<any>('api/auth/smartId', { idCode: idCode }); // as Observable<any>;
  }

  public authenticateSmartId(sessionId: string) {
    return this._http.get<any>(`api/auth/smartId/${sessionId}`);
  }

  public initVerifyViaSmartId(idCode: string) {
    return this._http.post<any>('api/auth/verifyId/smartId', { smartIdUserIdCode: idCode }); // as Observable<any>;
  }

  public verifySmartId(sessionId: string) {
    return this._http.get<any>(`api/auth/verifyId/smartId/${sessionId}`) as Observable<any>;
  }

  public initVerifyViaMobileId(id: string, phone: string, countryCode: string) {   
    
    return this._http.post<any>('api/auth/verifyId/mobileId', {
      mobileIdUserIdCode: id,
      mobileIdPhoneNumber: phone,
      prefix: countryCode
    });
  }

  public verifyMobileId(sessionId: string) {
    return this._http.get<any>(`api/auth/verifyId/mobileId/${sessionId}`);
  }

  public updateToken(cb: () => void) {
      
    return this._http.get<any>('api/auth/refresh')
      .subscribe(x => {        
        this._appState.token.next(x);
        cb();
      });
  }

  public setToken(token: IIdentity) {
    this._appState.token.next(token);
  }

  private async _setAuthCookie(sessionId: string) {
    await this._http.get<any>(`api/auth/finalize/${sessionId}`).toPromise();
  }

  private async _clearAuthCookie() {

    console.log('clearAuthCookie ' + new Error().stack);    
    await this._http.get<any>('api/auth/logout').toPromise();
  }

  // VERIFICATION

  // public verifyIdCard(): Observable<any> {
  //   return this._http.get<any>('api/Auth/verifyId/idCard') as Observable<any>;
  // }

  public verifyIdCardSession(sessionId: string): Observable<any> {
    return this._http.get<any>(`api/Auth/verifyId/idCard/${sessionId}`) as Observable<any>;
  }

  // public verifySmartId(data: any): Observable<any> {
  //   return this._http.post<any>('api/Auth/verifyId/smartId', data) as Observable<any>;
  // }

  // public verifyMobileId(data: any): Observable<any> {
  //   return this._http.post<any>('api/Auth/verifyId/mobileId', data) as Observable<any>;
  // }

  // public verifyMobileIdSession(sessionId: string) {
  //   return this._http.get<any>(`api/Auth/verifyId/mobileId/${sessionId}`) as Observable<any>;
  // }

  // public verifyBankId(data: any): Observable<any> {
  //   return this._http.post<any>('api/Auth/verifyId/bank', data) as Observable<any>;
  // }

  // public verifyBankIdResponse(data: any): Observable<any> {
  //   return this._http.post<any>('api/Auth/verifyId/bank/response', data) as Observable<any>;
  // }  
}

export interface IRegisterAccount {
  email: string;
  userName: string;
  password: string;
  coupon: string;
}
