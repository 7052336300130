<div class="nav-wrapper">
  <div class="nav-top-row">
    <div class="nav-left">
      <hamburger-toggle
        class="nav-btn"
        [isOpen]="showDrawer"
        (click)="toggleNavigatorContent()"
      >
      </hamburger-toggle>
      <div class="logo-container" (click)="onLogoClick()">
        <img id="logo" src="/assets/images/brand/logo-m.svg" />
      </div>
    </div>
    <div class="nav-center">
      <ng-container
        *ngIf="
          _appState.sportsBettingEnabled &&
          activeAppModule === TotoAppModule.Sports
        "
      >
        <div class="live-container" [routerLink]="livebetLink">
          Live
        </div>
        <!-- <ng-container *isAuthenticated> -->
        <nav-search class="search-navbar" [compact]="true"></nav-search>
        <!-- </ng-container> -->
      </ng-container>
    </div>
    <div class="nav-right">
      <user-block-mobile></user-block-mobile>
    </div>
  </div>
</div>

<nav class="nav-drawer" [class.open]="showDrawer">
  <div class="nav-top-row">
    <div class="nav-left">
      <hamburger-toggle
        class="nav-btn"
        [isOpen]="true"
        (click)="toggleNavigatorContent()"
      >
      </hamburger-toggle>
      <div class="logo-container">
        <img id="logo" src="/assets/images/brand/logo-m.svg" />
      </div>
    </div>
    <div class="nav-right">
      <nav-search *ngIf="
          _appState.sportsBettingEnabled &&
          activeAppModule === TotoAppModule.Sports
        "
        [compact]="true"
        class="search-drawer"
        (onOpenChange)="toggleNavigatorContent(!$event, !showDrawer)"
      ></nav-search>
    </div>
  </div>
  <div class="nav-content">
    <div class="flex flex-row">
      <a
        class="navlink-main"
        style="width: 50%;"
        *ngFor="let link of MAIN_MENU_LINKS | keyvalue"
        [href]="link.value.routerLink"
        [class.active]="activeAppModule == link.key"
      >
        {{ link.value.label | translate }}
      </a>
    </div>
    <div class="mt5">
      <ng-container *ngFor="let link of menuItems">
        <ng-container *ngIf="link.isAuthenticated ? isLoggedIn : true">
          <ng-container [ngSwitch]="link.component ? true : false">
            <a
              *ngSwitchDefault
              class="navlink"
              [routerLink]="link.routerLink"
              [class.disabled]="link.isDisabled"
              [routerLinkActiveOptions]="link.isActiveMatchOptions"
              routerLinkActive="active"
            >
              <badge
                *ngIf="link.badgeRef && this[link.badgeRef]"
                [count]="this[link.badgeRef]"
              ></badge>
              <svg-icon
                *ngIf="link.icon"
                class="navlink--icon"
                fontSize="32px"
                [key]="link.icon"
              ></svg-icon>
              <span class="navlink--label">
                {{ link.label | translate }}
              </span>
            </a>
            <ng-container
              dynamic-content
              *ngSwitchCase="true"
              [component]="link.component"
            ></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</nav>

<div
  class="nav-drawer-underlay"
  *ngIf="showDrawer"
  (click)="toggleNavigatorContent()"
></div>
