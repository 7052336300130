import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppState, TotoAppModule } from 'src/app/app.state';
import { MODULE_MENU_LINKS } from 'src/app/config/nav-links';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent {

  @Input() public contentMaxWidth = 'default';

  

  public get horseRacingLinks() {
    return MODULE_MENU_LINKS[TotoAppModule.HorseRacing].filter(x => !x.isAuthenticated && x.label);
  }

  public get sportsbettingLinks() {
    return MODULE_MENU_LINKS[TotoAppModule.Sports].filter(x => !x.isAuthenticated && x.label);
  }

  public affiliateLink = "https://affiliate.toto.ee/";

  constructor(public _state: AppState) {}

  // public rulesSectionId = 155;
  public privacyPolicySectionId = 153;
  public cookiePolicySectionId = 197;


  public get activeAppModule() {
    return this._state.activeAppModule;
  }

  public get linkRouteRoot() {
    return this.activeAppModule === TotoAppModule.HorseRacing ? '/horseracing' : '/sports';
  }


  public get rulesLink() {
    return `${this.linkRouteRoot}/instructions`
  }

  public get privacyPolicyLink() {
    return `${this.linkRouteRoot}/instructions?sectionId=${this.privacyPolicySectionId}`
  }

  public get cookiePolicyLink() {
    return `${this.linkRouteRoot}/instructions?sectionId=${this.cookiePolicySectionId}`
  }

}
