import { Component, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormGroup,
  FormControl
} from '@angular/forms';
import { IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import { ResponsiveHelper } from 'src/app/helpers/responsive';
import { AppState, TotoAppModule } from 'src/app/app.state';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService, LocaleService } from 'src/app/services';
import { MAIN_MENU_LINKS, MODULE_MENU_LINKS } from 'src/app/config/nav-links';
import { DEFAULT_LANGUAGE, LANGUAGE_OPTIONS } from 'src/app/app.settings';
import { NavigatorLanguagesBlockComponent } from './components/language-picker/nav-languages.component';

@Component({
  selector: 'navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent {
  public MAIN_MENU_LINKS = MAIN_MENU_LINKS;
  public languageOptions = LANGUAGE_OPTIONS;

  public TotoAppModule = TotoAppModule;

  public formGroup = new FormGroup({
    language: new FormControl(this.languageOptions[0])
  });

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public _appState: AppState,
    private _router: Router,
    public responsive: ResponsiveHelper,
    private _authService: AuthenticationService,
    private _localeService: LocaleService
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._appState.isNavigatorOpen = false;
        this._appState.isUserMenuOpen = false;
        this.document.body.classList.remove('noscroll');
      }
    });
  }

  public get activeAppModule(): TotoAppModule {
    return this._appState.appModule$.value;
  }

  public get moduleMenuLinks() {
    if (
      this.activeAppModule === TotoAppModule.Sports &&
      !this._appState.sportsBettingEnabled
    ) {
      return [
        // {
        //   label: null,
        //   routerLink: null,
        //   icon: null,
        //   component: NavigatorLanguagesBlockComponent,
        //   isMobile: true,
        //   isDesktop: false
        // }
      ];
    }
    return MODULE_MENU_LINKS[this.activeAppModule];
  }

  public get externalLinks() {
    return this._appState.siteLocale?.pages;
  }

  public get isLoggedIn() {
    return this._appState.isLoggedIn;
  }

  public get isMobile() {
    return this._appState.isViewMobile;
  }

  public get isTablet() {
    return this._appState.isViewTablet;
  }

  public get showMenu() {
    return this._appState.isUserMenuOpen;
  }

  public get showDrawer() {
    return this._appState.isNavigatorOpen;
  }

  public get userActiveTickets() {
    return this._appState.activeSportsBetsCount;
  }

  public toggleNavigatorContent(
    toggle = !this._appState.isNavigatorOpen,
    skip = false
  ) {
    if (skip) {
      return;
    }

    if (this.isMobile && toggle) {
      this.document.body.classList.add('noscroll');
    } else {
      this.document.body.classList.remove('noscroll');
    }

    this._appState.isNavigatorOpen = toggle;
  }

  public logOut() {
    this._authService.logout();
  }

  public onLogoClick() {
    this._router.navigate([this._authService.activeAppModuleRoot]);
  }
}
